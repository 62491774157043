/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"

function SEO({ description, lang, meta, title, image, schemaMarkup, translates, alternateOtherLanguages = true, amp = false }) {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const titleText = title.endsWith(" - Tecalis") ? title : `${title} - Tecalis`
    const metaDescription = description || site.siteMetadata.description
    const metaImage = image || site.siteMetadata.image

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={titleText}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:url`,
                    content: `https://www.tecalis.com${pathname}`
                },
                {
                    property: `og:image`,
                    content: "https://www.tecalis.com" + metaImage
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    property: `twitter:image`,
                    content: "https://www.tecalis.com" + metaImage
                }
            ].concat(meta)}
        >
            {amp && <html amp />}
            {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
            {metaImage && <link rel="image_src" href={"https://www.tecalis.com" + metaImage} />}
            {translates && Object.entries(translates).map(([key, value]) => {
                if (key === lang || alternateOtherLanguages) {
                    return <link rel="alternate" href={`https://www.tecalis.com${value}`} hrefLang={key} key={key} />
                }
            })}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `es`,
    meta: [],
    description: ``,
    title: `Tecalis`,
    image: require("../new/resources/quienes-somos.png").default

}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string
    // canonical: PropTypes.string,
}

export default SEO
