import React from "react"
import { graphql, Link } from "gatsby"
import { initializeHubspotForm } from "../utils/gatsby-helpers"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const RequestADemo = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const pathCaseStudy = {
        "en": "/case-studies",
        "es": "/es/casos-exito"
    }

    initializeHubspotForm(page.form.portal_id, page.form.form_id, "#rad-form-container", page.form.region)

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />
            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg white-section green-lg">
                    <div className="container flex-start">
                        <div className="container__50">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <GatsbyImage alt={page.photo.alternativeText} image={getImage(page.photo.localFile)} />
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="tile tile--white">
                                <h4>{page.form_title}</h4>
                                <div>
                                    <ReactMarkdown children={page.form_description} rehypePlugins={[rehypeRaw]} />
                                </div>
                                <div id="rad-form-container" className="form-hb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.trust_us_title}</h2>
                        <div className="grid-md">
                            {page.trust_us_logos.map((logo, index) => (
                                <GatsbyImage alt={logo.alt} image={getImage(logo.image.localFile)} key={index}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <div className="tile__body">
                                <ReactMarkdown children={page.trust_us_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="grid-sm-3">
                            {page.trust_us_metrics.map((metric, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl">{`${metric.number}${metric.symbol}`}</div>
                                    <span>
                                        <ReactMarkdown children={metric.description} rehypePlugins={[rehypeRaw]} />
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                {page.opinions.map((opinion, index) => {
                    return <div className={"main__section main__section--50 main__section--50--md" + (index % 2 === 0 ? " main__section--50-invert" : "")} key={index}>
                        <div className="container">
                            <div className="container__50">
                                <GatsbyImage alt={opinion.opinion.company_logo_simple.alternativeText} image={getImage(opinion.opinion.company_logo_simple.localFile)} />
                                <div className="container__text">
                                    “{opinion.opinion.message}”
                                </div>
                                <div className="avatar">
                                    <div className="avatar__photo">
                                        <GatsbyImage alt={opinion.opinion.photo.alternativeText} image={getImage(opinion.opinion.photo.localFile)} />
                                    </div>
                                    <div className="avatar__job">
                                        <b>{opinion.opinion.name}</b>
                                        <span>{opinion.opinion.job_position}</span>
                                    </div>
                                </div>
                                <Link to={`${pathCaseStudy[lang]}/${opinion.case_study.url}`} className="link--big">
                                    {opinion.button}
                                    <i className="icon-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="container__50">
                                <GatsbyImage alt={opinion.photo.alternativeText} image={getImage(opinion.photo.localFile)} />
                            </div>
                        </div>
                    </div>
                })}

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.digital_model_title}</h2>
                        <div className="grid-lg-4">
                            {page.digital_model_items.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="image-group" key={index} {...animation}>
                                    <GatsbyImage alt={item.photo.alternativeText} image={getImage(item.photo.localFile)} />
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default RequestADemo


export const indexPageQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2023RequestDemo ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    photo {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    trust_us_title
                    trust_us_logos {
                        alt
                        image {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 120
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    trust_us_description
                    trust_us_metrics {
                        number
                        symbol
                        description
                    }
                    opinions {
                        opinion {
                            name
                            job_position
                            message
                            company_logo_simple {
                                name
                                alternativeText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 80
                                            placeholder: BLURRED
                                            formats: [WEBP]
                                        )
                                    }
                                }
                            }
                            photo {
                                name
                                alternativeText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            height: 64
                                            width: 64
                                            placeholder: BLURRED
                                            formats: [WEBP]
                                        )
                                    }
                                }
                            }
                        }
                        case_study {
                            title
                            url
                        }
                        photo {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 652
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                        button
                    }
                    digital_model_title
                    digital_model_items {
                        title
                        description
                        photo {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 700
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    form_title
                    form_description
                    form {
                        region
                        portal_id
                        form_id
                    }
                }
            }
        }
        footer: markdownRemark (
            frontmatter: {
                name: {eq: "footer"}
                lang: {eq: $langKey}
            }
        ) {
            frontmatter {
                product {
                    name
                }
                identity {
                    name
                    url
                }
                signature {
                    name
                    url
                }
                rpa {
                    name
                    url
                }
                auth {
                    name
                    url
                }
                communication {
                    name
                    url
                }
                anti_fraud {
                    name
                    url
                }
                about_tecalis {
                    name
                }
                about_us {
                    name
                    url
                }
                news {
                    name
                    url
                }
                contact {
                    name
                    url
                }
                resources {
                    name
                }
                blog {
                    name
                    url
                }
                partners {
                    name
                    url
                }
                account {
                    name
                }
                privacy {
                    name
                    url
                }
                terms_use {
                    name
                    url
                }
                terms_conditions {
                    name
                    url
                }
                public_financing {
                    name
                    url
                }
                newsletter {
                    title
                    description
                }
                list_description {
                    title
                    description
                    one_title
                    one_content
                    two_title
                    two_content
                    three_title
                    three_content
                }
                copyright {
                    name
                }
            }
        }
    }
`
